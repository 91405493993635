<template>
  <div class="musicList">
    <ScrollBar ref='scrollbar' @refresh="refresh" @loadMore="loadMore">
      <SearchNav @getId="getId" @search="search" @visible='visible=true'></SearchNav>
      <div class='list_ontent' v-if='musiclist.length>0'>
        <div class='music' v-for="(item,idx) in musiclist" :key="idx" @click.prevent="handleMusic(item)">
          <div class="cover">
            <img class="cover-main" :src="require('@/assets/image/sound-effect-record.png')" />
            <img class="cover-fixed" :src="require('@/assets/svg/sound-effect-pause.svg')" />
          </div>
          <div class='music_aside'>
            <span class='music-name'>{{item.name}}</span>
            <div class='music_tag'>
              <span class='tag' v-for='(o,i) in item.label' :key='i'>{{o.name}}</span>
            </div>
          </div>
          <div class='music_opera'>
            <img :src="require(`@/assets/svg/${item.is_collect? 'collected' : 'collect'}.svg`)" @click.stop="handleMusicCollect(item)" />
            <!--<img :src="require('@/assets/svg/share-gray.svg')" @click="handleShare('', item.object_id, 3, item.name)" />-->
            <!--<img :src="require('@/assets/svg/download-gray.svg')" @click.stop="handleDownload(item, 'single')" />-->
            <img :src="require('@/assets/svg/download-gray.svg')" @click.stop="downloadFile(item)" />
          </div>
        </div>
      </div>
      <div class='nodata' v-else>
        <NoData></NoData>
      </div>
    </ScrollBar>
    <PlayBar v-if='Info' :musicInfo="Info" ref='audioRef'></PlayBar>
    <!--侧边栏（标签筛选）-->
    <DrawerTemp :visible="visible" :data="taglist" type="3"></DrawerTemp>
    <!--下载提升弹框-->
    <dialogBox :packageObj='packageObj' :visible='visibleDialog' v-if='visibleDialog'></dialogBox>
  </div>
</template>
<script>
import NoData from "./components/noData"
import dialogBox from "./components/DialigBox"
import ScrollBar from './components/ScrollBar';
import PlayBar from './components/PlayBar'
import SearchNav from "./components/SearchNav.vue";
import DrawerTemp from "./components/DrawerTemp.vue";
import Buttons from '@/layout/mixins/Buttons';
import Common from './mixins/Common';
import {getLabels} from '@/api/mobile';
import {fetchSoundEffect} from "@/api/soundEffect";
// import { EventBus } from '@/utils/bus'
export default{
  components:{
    ScrollBar,
    SearchNav,
    DrawerTemp,
    PlayBar,
    dialogBox,
    NoData
  },
  mixins: [Buttons,Common],
  data(){
    return{
      Info: null,
      musiclist: [],
      visible: false,
      taglist: [],
      keyword_name: '',
      order: '',
      activeTag: [],
      page: 1,
      pageSize: 20
    }
  },
  mounted() {
    this.getTaglist();
    this.getList();
    // EventBus.$on('getDownInfo', (data)=>{
    //   this.downloadItem(data)
    // })
  },
  methods:{
    handleMusic(item){
      this.Info = item;
      // this.$store.dispatch("mobile/updateCurrentMusic", {
      //   name: item.name,
      //   music_src: item.watermark_file,
      //   music_id: item.music_id,
      //   duration: item.duration,
      //   logo: item.logo
      // });
      // this.$store.dispatch("mobile/play");
    },
    //获取标签列表
    async getTaglist(){
      const {data, code ,msg} = await getLabels({
        type: 3
      });
      if(code !== 0){
        this.$message.error(msg);
        return;
      }
      this.taglist = data || [];
    },
    // 获取音效列表
    async getList(){
      if(window.sessionStorage.getItem('voiceTag')){
        let arr = window.sessionStorage.getItem('voiceTag');
        this.activeTag =  JSON.parse(arr) || [];
      }
      const {data, code ,msg} = await fetchSoundEffect({
        page: this.page,
        limit: this.pageSize,
        label_id: this.activeTag.join(","),
        order: this.order,
        keyword_name: this.keyword_name
      });
      if(code !== 0){
        this.$message.error(msg);
        return;
      }
      data && data.list.forEach(item=>{
        if(item.label){
          item.labelTag = item.label.map(o=>{
            return o.name;
          }).join("，")
        }
      });
      this.musiclist = data.list || [];
    },
    // 收藏
    async handleMusicCollect(item) {
      item.is_collect = item.is_collect === 0 ? 1 : 0;
      await this.handleCollect(item.object_id, 3, {
        ...item,
        type: 3,
        labels: item.label
      }, false)
    },
    getId(e){
      this.order = e.value;
      this.getList();
    },
    search(val){
      this.keyword_name = val;
      this.page = 0;
      this.pageSize = 20;
      this.getList();
    },
    // 下拉刷新
    refresh(){
      this.page = 1;
      this.pageSize = 20;
      this.getList();
      this.$refs.scrollbar.$refs.scroller.finishPullToRefresh();
    },
    // 上拉加载
    loadMore(){
      if(this.page === 1 && this.musiclist.length < 1){
        this.$refs.scrollbar.$refs.scroller.finishInfinite(true);
        return;
      }
      this.page += 1;
      if(window.sessionStorage.getItem('voiceTag')){
        let arr = window.sessionStorage.getItem('voiceTag');
        this.activeTag =  JSON.parse(arr) || [];
      }
      fetchSoundEffect({
        page: this.page,
        limit: this.pageSize,
        label_id: this.activeTag.join(","),
        order: this.order,
        keyword_name: this.keyword_name
      }).then(res=>{
        if(res.code !== 0){
          this.$message.error(res.msg);
          return;
        }
        if(res.data.list.length > 0){
          this.musiclist = this.musiclist.concat(res.data.list) || [];
          this.$refs.scrollbar.$refs.scroller.finishInfinite(false);
        }else{
          this.$refs.scrollbar.$refs.scroller.finishInfinite(true);
        }
      });
    },
    // downloadItem(item){
    //   fetch(decodeURIComponent(item.down_url)).then(res=>{
    //     let a = document.createElement('a');
    //     a.href = res.url;
    //     a.download = "download";
    //     a.click();
    //     window.URL.revokeObjectURL(res.url);
    //   });
    // }
  },
  beforeDestroy() {
    // this.$store.dispatch("mobile/updateCurrentMusic", {
    //   name: "",
    //   music_src: "",
    //   music_id: "",
    //   duration: "",
    //   logo: ""
    // });
    // this.$store.dispatch("mobile/pause");
    this.Info = null;
    window.sessionStorage.removeItem("voiceTag");
    if(this.$refs.audioRef){
      if(this.$refs.audioRef.playRef){
        this.$refs.audioRef.playRef.pause();
      }
      this.$refs.audioRef.playRef = null;
      this.$refs.audioRef.playing = false;
      this.$refs.audioRef.currentTime = 0;
    }
  }
}
</script>
<style lang="scss" scoped>
.musicList{
  width: 100%;
  height: 100%;
  .list_ontent{
    width: 100%;
    //margin-top: 16px;
    padding: 0 22px 0 20px;
    box-sizing: border-box;
    .music{
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      padding: 12px 0;
      box-sizing: content-box;
      border-bottom: 1px solid rgba(178,178,178,0.2);
      &:last-child{
        border-bottom: none;
      }
      .cover{
        flex: 0 0 50px;
        height: 100%;
        position: relative;
        .cover-main{
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          object-fit: cover;
        }
        .cover-fixed{
          display: inline-block;
          width: 20px;
          height: 20px;
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          transform: translate(-40%, -50%);
        }
      }
      .music_aside{
        flex: 1;
        padding: 4px 0 3px 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        overflow: hidden;
        .music-name{
          display: inline-block;
          width: 100%;
          height: 21px;
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(51,51,51,0.75);
          line-height: 21px;
          overflow: hidden;
          white-space:nowrap;
          text-overflow:ellipsis;
        }
        .music_tag{
          width: 100%;
          height: 16px;
          display: flex;
          align-items: center;
          margin-top: 6px;
          .tag{
            display: inline-block;
            padding: 0 5px;
            box-sizing: border-box;
            margin-right: 4px;
            border-radius: 4px;
            border: 1px solid rgba(51,51,51,0.3);
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(51,51,51,0.3);
          }
          //overflow: hidden;
          white-space:nowrap;
          text-overflow:ellipsis;
        }
      }
      .music_opera{
        margin-left: 12px;
        height: 100%;
        display: flex;
        align-items: center;
        img{
          display: inline-block;
          margin: 0 8px;
          &:first-child,&:last-child{
            height: 20px;
          }
          &:nth-child(2){
            height: 20px;
          }
        }
      }
    }
  }
}
.nodata{
  flex: 1;
}
/deep/{
  .swiper-container{
    .swiper-wrapper{
      height: auto !important;
    }
  }
}
</style>

/*
 * @Author: songyajuan
 * @Date: 2021-12-29 15:38:09
 * @Description: 音效相关请求
 * @Last Modified by: songyajuan
 * @Last Modified time: 2021-12-29 15:39:51
 */

import request from '@/utils/request'

/**
 * 音效列表
 * @param params
 * @param params.page 页码
 * @param params.limit 每页条数
 */
export function fetchSoundEffect(params) {
  return request.post('/fontend/Music/soundEffectsList', params, {})
}

// 音效标签
export function getSoundLabel(params) {
  return request.post('/fontend/LabelList/sound-label-list', params, {})
}



